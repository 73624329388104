.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 805px;
  margin: 0 auto;
  margin-top: 2px;
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  font-size: 0.875rem;
  line-height: 1.6;
  box-shadow: 0 -0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
  border-style: none;
}

.footerContent {
  p {
    margin: 0.3125rem 0;
    font-size: 17px;
  }

  p:first-of-type {
    font-size: 20px;
    font-weight: bold;
  }

  p:last-of-type {
    font-size: 0.8rem;
    color: #6c757d;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 10px;
    width: 84%;
  }

  .footerContent p {
    font-size: 14px;
  }

  .footerContent p:first-of-type {
    font-size: 16px;
  }

  .footerContent p:last-of-type {
    font-size: 12px;
  }
}

@media (max-width: 370px) {
  .footer {
    width: 83%;
  }

  .footerContent {
    p {
      font-size: 10px;
    }
  }

  .footerContent p {
    font-size: 10px;
  }

  .footerContent p:first-of-type {
    font-size: 14px;
  }

  .footerContent p:last-of-type {
    font-size: 8px;
  }
}
