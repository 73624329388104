html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.app-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 20px;
}

.content {
  flex: 1;
}
