.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
}

.logo {
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  position: relative;
  right: 45%;
}

.navList {
  display: flex;
  list-style-type: none;
  gap: 15px;
  position: relative;
  left: 80%;
}

.link {
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: 500;

  &:hover {
    color: #ff8888;
  }
}

.activeLink {
  text-decoration: none;
  color: #ff8888;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .header {
    padding: 10px;
    width: 20rem;
  }

  .logo {
    font-size: 13px;
    position: unset;
    transform: translateX(-5%);
  }

  .navList {
    gap: 5px;
    position: unset;
  }

  .link,
  .activeLink {
    font-size: 11px;
  }
}

@media (max-width: 400px) {
  .logo {
    transform: translateX(5%);
  }

  .navList {
    transform: translateX(-10%);
  }

  .link,
  .activeLink {
    font-size: 10px;
  }
}

@media (max-width: 360px) {
  .navList {
    transform: translateX(-20%);
  }
}
