.aboutImage {
  height: auto;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .aboutImage {
    width: 90%;
  }
}
