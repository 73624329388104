.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  margin: 0 auto;
  padding: 10px;
  width: 20rem;
}

.messageUs {
  text-align: center;
  margin-bottom: 2rem;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
}

.formSection {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    font-family: inherit;
    outline: none;

    &:focus {
      border-color: #ff8888;
    }
  }

  textarea {
    resize: none;
  }
}

.submitButton {
  background-color: #ff8888;
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin: 0 auto;

  &:hover {
    background-color: #ff8888;
  }
}

.error {
  color: red !important;
  font-size: 0.9rem;
}

.success {
  color: blue !important;
  font-size: 0.9rem;
}

@media (max-width: 440px) {
  .contactContainer {
    width: 87.5%;
    padding: 5px;
  }

  .submitButton {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 380px) {
  .contactContainer {
    width: 87%;
    padding: 5px;
  }

  .messageUs h2 {
    font-size: 1.5rem;
  }

  .messageUs p {
    font-size: 0.9rem;
  }

  .submitButton {
    font-size: 0.75rem;
  }
}
