.contactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 840px;
  padding: 0;
}

.contactWrapper {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
}

.infoSection {
  background-color: #f7f7f7;
  padding: 3rem;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #333;

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
}

.formSection {
  width: 60%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nameGroup {
  display: flex;
  gap: 2vw;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  flex: 1;

  label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    font-family: inherit;
    outline: none;
    &:focus {
      border-color: #ff8888;
    }
  }

  textarea {
    resize: none;
  }
}

.submitButton {
  background-color: #ff8888;
  color: #fff;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: #ff8888;
  }
}

.error {
  color: red !important;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.success {
  color: blue !important;
  font-size: 0.9rem;
  margin-top: 1rem;
}
